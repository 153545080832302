import React from 'react';
import { ExpandRightIcon } from '@diligentcorp/atlas-react-icons';
import { ToolKitIcon, AutoAnalysisIcon, BranchIcon, ArrowLink } from '@devportal/common';
import { Button } from '@mui/material';

function IdeasHubExplore() {
  return (
    <div className="bg-dark-500 text-white">
      <div className="px-2 py-8 flex min-h-140 mx-auto container items-center">
        <div className="flex justify-between w-full min-h-90 flex-wrap">
          <div className="flex justify-center">
            <div className="flex flex-col justify-between max-w-100">
              <div>
                <div className="font-semibold text-5xl mb-6">IdeasHub</div>
                <p className="mb-6">
                  Get inspired with ideas to get you started with analysis, automation, integrations and reporting using
                  Diligent One platform developer tools.
                </p>
              </div>
              <a href="ideashub">
                <Button className="bg-brand-100 h-14 w-54 rounded-md text-white font-bold  group">
                  <p>Explore all our Ideas</p>
                  <ExpandRightIcon className="transition-transform transform group-hover:translate-x-2" />
                </Button>
              </a>
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="w-66 p-8 rounded-lg flex flex-col justify-between bg-white text-text-800">
              <div>
                <AutoAnalysisIcon />
                <h1 className="text-xl-05 font-semibold">Automating Analysis</h1>
                <p className="text-sm text-dark-0">
                  Start with simple ideas to automate controls testing for IT controls, Financial controls and other key
                  controls
                </p>
              </div>
              <ArrowLink
                name="Explore"
                isUnderline={false}
                wrapperProps={{ className: 'text-brand-300 font-semibold' }}
                href="/ideashub?filter=CAT1&page=1"
              />
            </div>
            <div className="w-66 p-8 rounded-lg flex flex-col justify-between bg-white text-text-800">
              <div>
                <ToolKitIcon />
                <h1 className="text-xl-05 font-semibold">Toolkits</h1>
                <p className="text-sm mt-10 text-dark-0">
                  Package up automations, reports and other helpful tools into a Toolkit to install on multiple
                  environments
                </p>
              </div>
              <ArrowLink
                name="Explore"
                isUnderline={false}
                wrapperProps={{ className: 'text-brand-300 font-semibold' }}
                href="/ideashub?filter=CAT2&page=1"
              />
            </div>
            <div className="w-66 p-8 rounded-lg flex flex-col justify-between bg-white text-text-800">
              <div>
                <BranchIcon />
                <h1 className="text-xl-05 font-semibold">Integration</h1>
                <p className="text-sm mt-10 text-dark-0">
                  Create integrations to any number of data sources and source systems using connectors and Robots
                </p>
              </div>
              <ArrowLink
                name="Explore"
                isUnderline={false}
                wrapperProps={{ className: 'text-brand-300 font-semibold' }}
                href="/ideashub?filter=CAT3&page=1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { IdeasHubExplore };
