import React from 'react';
import footerLogo from '../../images/FooterDiligentLogo.png';
import { YouTubeIcon, FacebookIcon, TwitterIcon, LinkedinIcon, LinkIcon } from '../Icons';
import { contents } from '../../contents';
import { Link } from 'react-router-dom';
import { ArrowLink } from '@devportal/common';

const footerLinks = [
  {
    heading: 'REFERENCE',
    children: [
      {
        label: 'Help Docs',
        href: 'https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=highbond-help',
        target: '_blank',
      },
      {
        label: 'API Terms of Use',
        href: 'https://developer.diligent.com/docs/api_terms_of_use',
        target: '_blank',
      },
    ],
  },
  {
    heading: 'GUIDES',
    children: [
      {
        label: 'ACL Guide',
        href: 'https://help.highbond.com/helpdocs/analytics/current-version/en-us/Default.htm#cshid=using-acl-analytics',
        target: '_blank',
      },
      {
        label: 'HCL Guide',
        href: 'https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=rob-scripting-in-robots',
        target: '_blank',
      },
    ],
  },
  {
    heading: 'COMPANY',
    children: [
      {
        label: 'About Us',
        href: 'https://www.diligent.com/company/about-us/',
        target: '_blank',
      },
      {
        label: 'Careers',
        href: 'https://www.diligent.com/company/careers',
        target: '_blank',
      },
      {
        label: 'Partners',
        href: 'https://www.diligent.com/showcase/',
        target: '_blank',
      },
      {
        label: 'Newsroom',
        href: 'https://www.diligent.com/company/newsroom',
        target: '_blank',
      },
    ],
  },
];

function Footer(props) {
  return (
    <div className="bg-dark-500 ">
      <div className="h-2 bg-brand-300 w-full"></div>
      <div className="container mx-auto flex items-center h-full p-2 min-h-80">
        <div className="min-h-54 flex flex-wrap justify-between w-full">
          <div className="flex-1 flex flex-col justify-between text-white">
            <div>
              <img src={footerLogo} />
              <div className="flex gap-3 my-3">
                <Link to={{ pathname: 'https://www.facebook.com/DiligentCorporation' }} target="_blank">
                  <FacebookIcon />
                </Link>
                <Link
                  to={{ pathname: 'https://www.linkedin.com/company/diligent-board-member-services/' }}
                  target="_blank"
                >
                  <LinkedinIcon />
                </Link>
                <Link to={{ pathname: 'https://twitter.com/diligenthq' }} target="_blank">
                  <TwitterIcon />
                </Link>
                <Link to={{ pathname: 'https://www.youtube.com/user/diligentboardbooks' }} target="_blank">
                  <YouTubeIcon />
                </Link>
              </div>
              <p>
                Contact Us
                <br />
                +1 604 669 4225
              </p>
            </div>
          </div>

          <div className="flex-1 flex flex-wrap justify-around text-white">
            {footerLinks.map((parent) => (
              <div>
                <div className="font-semibol mb-3">{parent.heading}</div>
                {parent.children.map((child) => (
                  <ArrowLink
                    textProps={{ className: 'text-xs mb-2 text-white' }}
                    isIcon={false}
                    name={child.label}
                    isUnderline={false}
                    to={{ pathname: child.href }}
                    target={child?.target ? child.target : '_self'}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="w-full flex justify-center container mx-auto flex-wrap"
        style={{ borderTop: '1px solid white', bottom: 0 }}
      >
        <div className="flex gap-6 text-white py-4 items-center">
          <span className="text-xs text-white" style={{ cursor: 'default' }}>
            © 2024 Diligent Corporation
          </span>
          <ArrowLink
            textProps={{ className: 'text-xs text-white' }}
            isIcon={false}
            name="Privacy Policy"
            isUnderline={false}
            to={{ pathname: 'https://www.diligent.com/privacy' }}
            target="_blank"
          />
          <ArrowLink
            textProps={{ className: 'text-xs text-white' }}
            isIcon={false}
            name="Terms of Use"
            isUnderline={false}
            to={{ pathname: 'https://developer.diligent.com/docs/terms_of_use' }}
            target="_blank"
          />
          <ArrowLink
            textProps={{ className: 'text-xs text-white' }}
            isIcon={false}
            name="Diligent Vulnerability Disclosure Program"
            isUnderline={false}
            to={{ pathname: 'https://www.diligent.com/vulnerability-disclosure-program' }}
            target="_blank"
          />
        </div>
      </div>
    </div>
  );
}
export { Footer };
