import React, { useState, useRef, useEffect } from 'react';
import {
  BannerLayout,
  Footer,
  queryKeys,
  getSignedUrl,
  getCodeSampleDetails,
  ScriptDownload,
  ArrowLink,
  OIDC,
  cookie,
} from '@devportal/common';
import { useQuery, useMutation } from 'react-query';
import { Skeleton, CircularProgress } from '@mui/material';
import { WithNavLayout } from 'components';
import { saveAs } from 'file-saver';
import atob from 'atob';
import './index.css';
import { TbCopy } from 'react-icons/tb';
import ReactDOMServer from 'react-dom/server';
import { Link, RouteComponentProps } from 'react-router-dom';

function CodeSamplesDetailsPage({ match }: RouteComponentProps) {
  const accessToken = cookie.get('accessToken');

  const loginHandler = async () => {
    window.localStorage.setItem('redirectPath', window.location.href);
    const url = await OIDC.signIn();
    window.location.replace(url);
  };
  if (!accessToken?.length) loginHandler();

  const mdConteinerRef: any = useRef();
  const [scrollIndex, setScrollIndex] = useState(0);
  const [activeFilePath, setActiveFilePath] = useState();

  const { data: codeSample, isLoading: isSampleLoading } = useQuery(
    [
      queryKeys.CODE_SAMPLE_DETAIL,
      {
        sampleId: match?.params?.id,
      },
    ],
    getCodeSampleDetails,
    {
      onSuccess: (data) => {
        if (!data?.data) {
          loginHandler();
        }
      },
    },
  );

  const codeSampleDetails = codeSample?.data;
  const scriptsLength = codeSampleDetails?.attributes?.cs_script_files?.length;
  const ideasLength = codeSampleDetails?.attributes?.cs_ideas_links?.length;
  const htmlString = atob(codeSampleDetails?.attributes?.cs_readme_file?.file_path || '');

  const parser = new DOMParser();
  const documentFromString = parser.parseFromString(htmlString, 'text/html');
  const h2Tags = documentFromString.querySelectorAll('h2');
  const h2List: any = [];

  h2Tags.forEach((h2, i) => {
    h2List.push(h2.textContent);
    return h2.setAttribute('id', `scroll-tag-id-${i}`);
  });

  function createCopyButton(codeElement) {
    const btnContainer = document.createElement('div');
    btnContainer.className = 'w-full flex justify-end copy-button';
    const copyButton = document.createElement('button');
    copyButton.textContent = 'Copy Code';
    copyButton.className =
      'copy-button flex items-center text-white bg-sea-600 font-bold rounded-md p-2 mb-2 text-base cursor-pointer';
    const iconElement = ReactDOMServer.renderToString(<TbCopy className="text-xl mr-1" />);

    copyButton.innerHTML = iconElement + copyButton.textContent;
    btnContainer.appendChild(copyButton);
    codeElement.parentNode.insertBefore(btnContainer, codeElement);
  }

  const codeBlocks = documentFromString.querySelectorAll('code');
  codeBlocks.forEach(function (codeBlock) {
    createCopyButton(codeBlock);
  });

  const { mutate: mutateSignedUrl, isLoading: signinIsLoading } = useMutation({
    mutationFn: getSignedUrl,
    onSuccess: (data) => {
      saveAs(data?.data?.attributes?.signed_url, 'script_file');
    },
  });

  const onScriptDownload = ({ filePath }) => {
    setActiveFilePath(filePath);
    mutateSignedUrl({ queryKey: ['', { filePath }] });
  };

  useEffect(() => {
    const observer: any = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const splittedId = entries[0].target.id.split('-');
        setScrollIndex(Number(splittedId[splittedId.length - 1]));
      }
    });
    if (mdConteinerRef?.current) {
      h2List.forEach((_, i) => {
        const id = `scroll-tag-id-${i}`;
        observer.observe(document.getElementById(id));
      });
    }
  }, [isSampleLoading]);

  const copyCodeHandler = (event) => {
    if (event.target.classList.contains('copy-button')) {
      const codeElement = event.target.parentElement.nextElementSibling;
      const codeContent = codeElement.textContent;

      navigator.clipboard
        .writeText(codeContent)
        .then(() => {
          event.target.textContent = 'Copied!';
          setTimeout(() => {
            event.target.textContent = 'Copy Code';
          }, 1000);
        })
        .catch(function (err) {
          console.error('Error copying code:', err);
        });
    }
  };

  return (
    <WithNavLayout>
      <BannerLayout
        type="light"
        breadcrumpList={[
          { name: 'Home', href: '/' },
          { name: 'Code Samples', href: '/code-samples' },
          { name: codeSampleDetails?.attributes?.cs_short_name, href: `/code-samples/${codeSampleDetails?.id}` },
        ]}
        title={codeSampleDetails?.attributes?.cs_title}
        isDataLoading={isSampleLoading}
        description={codeSampleDetails?.attributes?.cs_description}
      ></BannerLayout>

      <div className="container mx-auto p-16">
        <div className="flex">
          <div className="pr-8 h-full w-full overflow-auto">
            {isSampleLoading ? (
              <div className="justify-center flex">
                <CircularProgress size={80} />
              </div>
            ) : (
              <div className="h-[80vh]" id="md-details-container" ref={mdConteinerRef} onClick={copyCodeHandler}>
                <div dangerouslySetInnerHTML={{ __html: documentFromString?.documentElement?.innerHTML || '' }}></div>
              </div>
            )}
          </div>
          <div className="w-105">
            <div className="py-2 px-4" style={{ borderLeft: '1px solid #CDD7E5' }}>
              <div className="font-bold mb-4">ON THIS PAGE</div>
              {isSampleLoading ? (
                Array(7)
                  .fill(2)
                  .map((item) => (
                    <Skeleton key={item} variant="rectangular" width="full" height={20} className="mt-2" />
                  ))
              ) : (
                <>
                  {h2List?.map((h2tag, index) => (
                    <div
                      key={index}
                      className={`text-sm mb-2 cursor-pointer ${scrollIndex == index ? 'font-bold text-brand-300' : ''}`}
                      onClick={() => {
                        setScrollIndex(index);
                        document
                          .getElementById(`scroll-tag-id-${index}`)
                          ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
                      }}
                    >
                      {h2tag}
                    </div>
                  ))}
                </>
              )}
            </div>

            {isSampleLoading || scriptsLength > 0 ? (
              <div className="my-16 rounded-md p-3 border-legacy-denim-light" style={{ border: '1px solid #CDD7E5' }}>
                <div className="font-bold mb-4">Files</div>
                {isSampleLoading
                  ? Array(4)
                      .fill(2)
                      .map((item) => (
                        <Skeleton key={item} variant="rectangular" width="full" height={36} className="mt-2" />
                      ))
                  : codeSampleDetails?.attributes?.cs_script_files?.map((file, index) => (
                      <ScriptDownload
                        key={index}
                        className={`bg-sea-50  ${index !== scriptsLength - 1 ? 'mb-2' : ''}`}
                        btnClick={() => onScriptDownload({ filePath: file?.file_path })}
                        isLoading={activeFilePath === file?.file_path && signinIsLoading}
                        name={file?.file_name}
                        filesLen={scriptsLength}
                        link="#"
                      />
                    ))}

                {codeSampleDetails?.attributes?.cs_prereq_scripts?.length ? (
                  <p className="text-sm font-bold">Prerequisites</p>
                ) : null}

                {codeSampleDetails?.attributes?.cs_prereq_scripts?.map((file, index) => (
                  <ScriptDownload
                    key={index}
                    className={`bg-bg-100 mb-2`}
                    name={file?.cs_name}
                    link={file?.file_path?.split('/')[file?.file_path?.split('/')?.length - 1] || '#'}
                    isDownload={false}
                  />
                ))}

                {codeSampleDetails?.attributes?.cs_dependent_scripts?.length ? (
                  <p className="text-sm font-bold">Dependent Code Snippets</p>
                ) : null}

                {codeSampleDetails?.attributes?.cs_dependent_scripts?.map((file, index) => (
                  <ScriptDownload
                    key={index}
                    className={`bg-orange-50 mb-2`}
                    btnClick={() => onScriptDownload({ filePath: file?.file_path })}
                    isLoading={activeFilePath === file?.file_path && signinIsLoading}
                    name={file?.cs_name}
                    filesLen={scriptsLength}
                    link={file?.file_path?.split('/')[file?.file_path?.split('/')?.length - 1] || '#'}
                    isDownload={false}
                  />
                ))}
              </div>
            ) : null}

            {isSampleLoading || ideasLength > 0 ? (
              <div className="rounded-md p-3" style={{ border: '1px solid #CDD7E5' }}>
                <div className="font-bold mb-4">Used in Ideas</div>
                {isSampleLoading
                  ? Array(4)
                      .fill(2)
                      .map((item) => (
                        <Skeleton key={item} variant="rectangular" width="full" height={36} className="mt-2" />
                      ))
                  : codeSampleDetails?.attributes?.cs_ideas_links?.map((file, index) => (
                      <div
                        key={index}
                        className={`flex w-full text-brand-300 py-3 justify-between px-2 items-center`}
                        style={index == 0 ? {} : { borderTop: '1px solid #CDD7E5' }}
                      >
                        <div className="w-full break-all items-center relative">
                          <Link
                            to={`/ideashub/${file?.file_path?.split('/')[file?.file_path?.split('/')?.length - 1]}`}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                          >
                            <div
                              className="text-xs font-bold text-brand-300 no-underline"
                              style={{ textDecoration: 'none' }}
                            >
                              {file?.ideas_name}
                              <ArrowLink
                                className="text-brand-300 absolute right-0 mb-4"
                                href={`/ideashub/${file?.file_path?.split('/')[file?.file_path?.split('/')?.length - 1]}`}
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </WithNavLayout>
  );
}
export { CodeSamplesDetailsPage };
