import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { OIDC, cookie } from '@devportal/common';
import { CircularProgress } from '@mui/material';

function OidcCallbackPage() {
  const { pathname } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams.get('code')) {
      responseProcessor();
    }
  }, [pathname]);

  const responseProcessor = async () => {
    const { access_token } = await OIDC.processSigninResponse(window.location.href);

    if (access_token?.length) {
      cookie.set('accessToken', access_token, { path: '/' });
    }

    const redirectPath = window.localStorage.getItem('redirectPath');

    if (redirectPath?.length) {
      window.opener.postMessage({ redirectPath }, '*');
    } else {
      window.opener.postMessage({ redirectPath: '/' }, '*');
    }
    window.close();
  };
  return (
    <div className="h-[100vh] w-[100vw] flex justify-center items-center">
      <CircularProgress />
    </div>
  );
}

export { OidcCallbackPage };
