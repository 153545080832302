import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, OIDC, cookie } from '@devportal/common';

function WithNavLayout({ children }: any) {
  const { pathname } = useLocation();
  const accessToken = cookie.get('accessToken');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const childResponse = (event) => {
      if (event?.data?.redirectPath) {
        const sanitizedRedirectPath = encodeURI(event.data.redirectPath);
        const currentDomain = window.location.origin;
        const redirectDomain = new URL(sanitizedRedirectPath).origin;

        if (currentDomain === redirectDomain) {
          window.location.replace(sanitizedRedirectPath);
        } else {
          console.log('Redirecting to external domain is not allowed.');
        }
      }
    };
    window.addEventListener('message', childResponse);
    return () => window.removeEventListener('message', childResponse);
  }, []);

  const logInOutHandler = async () => {
    if (accessToken?.length) {
      cookie.remove('accessToken', { path: '/' });
      const url = await OIDC.signOut();
      window.location.assign(url);
    } else {
      window.localStorage.setItem('redirectPath', window.location.href);
      const url = await OIDC.signIn();
      const windowProps = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1200, height=800`;
      window.open(url, '_blank', windowProps);
    }
  };

  return (
    <div>
      <div className="bg-white w-full fixed" style={{ top: 0, zIndex: 200 }}>
        <Navbar isloggedIn={!!accessToken?.length} onLogInOutClick={logInOutHandler} />
      </div>

      <div style={{ marginTop: '104px' }}>{children}</div>
    </div>
  );
}

export { WithNavLayout };
