import React from 'react';
import { ArrowLink } from '@devportal/common';

function GridCard({ className, icon, title, description, desClass, links, linkClass, ...rest }: any) {
  return (
    <div
      className={`flex-1 bg-cover min-h-120 min-w-80 px-10 py-20 md:py-16 sm:py-10 flex justify-center items-center ${className}`}
      {...rest}
    >
      <div className="max-w-120 flex flex-col justify-between h-full">
        <div>
          {icon}
          <h1 className="text-4xl font-bold my-7">{title}</h1>
          <p className={`mb-8 ${desClass || ''}`}>{description}</p>
        </div>

        <div>
          {links.map((link, index) => {
            return (
              <ArrowLink
                key={index}
                name={link.name}
                href={link?.target ? { pathname: link.href } : link.href}
                onClick={(event: MouseEvent) => {
                  if (link?.shouldReload) {
                    event.preventDefault();
                    window.open(link.href, link.target ? link.target : '_self');
                  }
                }}
                wrapperProps={{ className: `${links.length - 1 == index ? '' : 'mb-2'} ${linkClass || ''}` }}
                target={link?.target ? link.target : '_self'}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export { GridCard };
